$circleDiameter: 1.4em;
$buttonWidth: 44px;
$toolbarHeight: 44px;

assignment-toolbar {
  .assignment-toolbar {
    box-shadow: $boxShadow;
    // The md-sidenav is set to z-index of 60, so this is one higher so the toolbar
    // displays properly
    z-index: 61;
    color: $ckHeaderGrey;
    height: inherit;
    width: 100%;

    .button-bar {
      width: 100vw;
      padding: 0 1em;
      height: $toolbarHeight;

      > div {
        outline: none;
      }
    }

    .hide {
      display: none;
    }

    .ck-volume {
      font-size: 1.5em;
    }
  }

  .assignment-toolbar-separator {
    width: 1px;
    height: $assignmentNavSeparator;
    margin: auto 2px;
    background-color: $dividerGrey;
  }

  .body {
    width: 100%;
    z-index: 1;

    ng-transclude {
      width: 100%;
    }

    md-sidenav {
      box-shadow: $boxShadow;

      .capture-audio {
        audio-capture {
          margin: 1em;
        }
      }

      .capture-ai-assistant {
        ai-assistant-capture {
          margin: 1em;
        }
      }

      .sidenav-header {
        border-bottom: 1px solid $dividerGrey;
        color: $ckHeaderGrey;
        font-family: $fontRoundedMedium;
        font-size: 1.6rem;
        height: 60px;
        width: 100%;
        padding: 0 0 0 16px;

        .sidenav-header-close {
          @extend .md-button.square;

          margin: 0;
          max-width: 45px;
          min-width: 45px;
          height: 100%;
        }
      }

      .place-stickers,
      .edit-sticker {
        md-content {
          box-sizing: border-box;
          width: 100%;
        }

        md-list {
          background-color: white;
        }

        md-list-item.sticker {
          height: 48px;
          border-bottom: 1px solid $dividerGrey;

          .md-button.md-no-style {
            padding-right: 0;
            padding-left: 0;
          }

          &.list-button .md-button.md-no-style {
            padding-left: 16px;
          }

          .sticker-item-content {
            width: 100%;
            max-width: 267px;
            z-index: 100;
            height: 48px;
            padding-left: 16px;
          }

          .img-container {
            width: 30px;
            height: 30px;

            margin-right: 0.6em;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          .text,
          .score {
            color: $ckHeaderGrey;
          }

          .text {
            outline: none;
          }

          .score {
            margin-right: 19px;
          }

          .edit {
            height: inherit;

            .md-button.bar-item {
              height: 48px;
              width: 48px;
              padding-left: 4px;
              padding-right: 4px;
            }
          }

          &.selected {
            background-color: $ckHeaderGrey;

            .text,
            .score,
            .ck-edit {
              color: white;
            }
          }
        }
      }

      .edit-sticker {
        .change-image {
          padding: 1.6rem 1.6rem 0 1.6rem;

          .md-button {
            width: 11em;
            height: 9em;
            margin: 0;
          }
          img {
            padding: 0.5em;
            max-height: 5em;
            max-width: 5em;
          }
        }

        .change-text {
          padding: 1.6rem 1.6rem 0 1.6rem;

          md-input-container {
            margin: 0;
            padding-left: 0;
            padding-right: 0;
          }
        }

        .change-points {
          margin-top: 1.6rem;

          .md-button {
            min-width: 3.2rem;
            width: 3.2rem;
            min-height: 3.2rem;
            padding: 0;
          }

          md-input-container {
            margin-top: 0;
            margin-bottom: 16px;
            width: 30%;
          }
        }

        .actions .md-button.save {
          margin-top: 2rem;
          width: 55%;
        }

        .actions .md-button.delete {
          margin: 0;
        }
      }

      .edit-fill-in-the-blank,
      .edit-multiple-choice {
        .onboarding {
          p,
          .section-header {
            padding: 0 16px;
            margin: 0;
          }

          .section-header {
            width: 100%;
          }

          p:first-of-type,
          .section-header {
            margin-top: 16px;
          }

          p {
            font-size: 13px;
            width: 100%;
          }

          img {
            max-width: 60%;
          }
        }
      }

      .edit-fill-in-the-blank {
        md-content {
          box-sizing: border-box;
          width: 100%;
        }

        md-list {
          background-color: white;
        }

        md-list.answer-list {
          padding-bottom: 10px;
        }

        md-list-item.answer {
          height: auto;
          border-bottom: 1px solid $dividerGrey;

          input {
            border: none;
            height: 100%;
            color: $ckHeaderGrey;
            font-weight: 400;
            font-family: $fontRoundedBook;
            outline: none;
          }

          .trash {
            height: inherit;

            .md-button.bar-item {
              height: 48px;
              width: 48px;
              padding-left: 4px;
              padding-right: 4px;
            }
          }
        }

        .change-points {
          border-top: 1px solid $dividerGrey;
          height: 100px;

          .md-button {
            min-width: 3.2rem;
            width: 3.2rem;
            min-height: 3.2rem;
            padding: 0;
          }

          md-input-container {
            margin-top: 0;
            margin-bottom: 16px;
            width: 30%;
          }
        }

        .fitb-answer-select {
          padding: 1.6rem;
          border-bottom: 1px solid $dividerGrey;
          display: flex;
          align-items: center;

          md-input-container {
            margin: 0 0 0 0.5rem;
          }

          .md-button {
            min-width: auto;
            padding: 0 0.5rem;
            margin: 0;
          }
        }

        .fitb-math {
          font-size: 1.5em;
          display: block;
          width: 80%;
          z-index: 1;
        }

        math-field::part(virtual-keyboard-toggle) {
          &:hover::after {
            left: auto;
          }
        }
      }

      .edit-multiple-choice {
        md-content {
          padding: 1em 1.5em;

          h4 {
            margin: 0.75em 0;
          }

          .ck-round-button.ck-secondary {
            border-width: 2px;
            height: 2.5em;
            width: 2.5em;
            min-width: 2.5em;
            min-height: 2.5em;
            line-height: 1.3em;
            margin: 0.3em;
            padding: 0;
            font-size: 1.5rem;
          }

          .correct-answer,
          .correct-answer:hover {
            background-color: $ckHeaderGrey;
            color: white;
          }

          .change-points {
            margin-top: 1.6rem;

            .md-button {
              min-width: 3.2rem;
              width: 3.2rem;
              min-height: 3.2rem;
              padding: 0;
            }

            md-input-container {
              margin-top: 0;
              margin-bottom: 16px;
              width: 30%;
            }
          }

          .actions {
            margin: 1em;
          }
        }

        .onboarding {
          p:not(:first-of-type) {
            padding-top: 15px;
          }

          .md-button {
            margin-top: 15px;
          }

          img.enter-answer-and-score-mc {
            max-width: 120px;
          }

          img.move-mc,
          img.student-enter-answer-mc {
            max-width: 160px;
          }
        }
      }

      .slide-background {
        .no-background .md-button {
          margin-bottom: 0;
          margin-top: 15px;
        }

        .background-colors {
          border-bottom: 1px solid $dividerGrey;
          padding-top: 5px;
          padding-bottom: 16px;

          .palette-container {
            width: 240px;
            height: 90px;
          }

          .color-button {
            @extend .md-button.square;

            width: 30px;
            min-width: 30px;
            height: 30px;
            margin: 0;
            padding: 0;

            .circle {
              width: $circleDiameter;
              height: $circleDiameter;
              border-radius: 50%;
              border: 1px solid $dividerGrey;
            }
          }
        }

        .md-button.slide-background-item {
          @extend .md-button.square;
          height: unset;
          max-height: 180px;
          width: 100%;
          margin: 0;
          padding: 12px;

          .image-container {
            width: 120px;
            max-height: 156px;
            overflow: hidden;
            border: 1px solid $disabledGrey;

            img {
              width: 100%;
            }
          }

          &:hover .trash {
            display: inherit;
          }

          .trash {
            display: none;
            height: 40px;
            width: 40px;
            outline: none;
            position: absolute;
            top: 0;
            right: 0;

            .ck-trash {
              color: $lightText;
            }

            &:hover .ck-trash {
              color: $ckHeaderGrey;
            }
          }
        }

        .add-image-background {
          border-top: 1px solid $dividerGrey;
          height: 60px;
        }
      }
    }
  }

  .enhanced-mc {
    .delete-btn {
      border-width: 0 !important;
      float: right;
    }

    .set-points {
      margin-top: 80px !important;
    }
  }
}

.assignment-toolbar {
  font-family: $fontRoundedBook;

  &.tool-panel {
    background: white;
    border-radius: 3px;
    box-shadow: $boxShadowCard;
  }

  .collapsed-button-bar {
    height: $toolbarHeight;
  }

  .tool-button {
    @extend .md-button.square;
    @include transition(background-color, 300ms);

    width: $buttonWidth;
    min-width: $buttonWidth;
    height: $toolbarHeight;
    margin: 0;
    padding: 0;
    color: $ckHeaderGrey;
    text-transform: none;

    .anchor {
      position: relative;
      width: 100%;
      height: 0;

      .color-bar {
        width: 100%;
        height: 3px;
        top: 2px;
        position: relative;
      }
    }

    .sub-icon {
      width: 0;
      height: $toolbarHeight;
      position: absolute;

      i {
        position: relative;
        top: 15px;
        left: 10px;
        font-size: 0.6em;
      }
    }

    .text-sub-icon {
      width: 0;
      height: $toolbarHeight;
      position: relative;

      i {
        position: relative;
        top: 15px;
        left: 0;
        font-size: 0.6em;
      }
    }

    &.toggled {
      background-color: $ckHeaderGrey;
      i {
        color: white;
      }
      .line-choice {
        background-color: white;
      }
    }

    &:disabled {
      color: $disabledGrey;
      i {
        color: $disabledGrey;
      }
    }
  }

  .tool-select {
    margin: 0;
    padding: 0;

    md-select .md-select-value,
    md-select:not([disabled]):focus .md-select-value {
      border-width: 0;
      height: $toolbarHeight;
      color: $ckHeaderGrey;
    }
  }

  .circle {
    width: $circleDiameter;
    height: $circleDiameter;
    border-radius: 50%;
    border: 1px solid $dividerGrey;
  }

  .line-choice {
    width: $buttonWidth;
    height: 2px;
    border-radius: 1000px;
    margin: 15px;
    width: 200px;
  }

  .feedback-notification {
    i {
      color: $ckGreen;
    }

    .notification-circle {
      position: relative;
      top: -9px;
      left: 1px;
      width: 0.5em;
      height: 0.5em;
      border-radius: 50%;
      background-color: $ckGreen;
    }
  }

  grade-input {
    height: 2.6rem;
  }

  grade-input.value {
    margin: auto;
    width: 85px;
  }

  grade-input.score {
    margin: auto;
    width: 130px;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  &.pen-color,
  &.text-color,
  &.text-background-color,
  &.text-border-color,
  &.highlighter-color,
  &.straight-line-color {
    width: calc(4 * #{$buttonWidth});

    .color-option-container {
      height: 500px;
      overflow-y: auto;
    }

    .custom-color-section {
      width: 100%;

      .custom-title {
        padding: 0 10px;
        font-size: 11px;
      }

      .custom-color-picker {
        width: 0px;
        height: 0px;
        position: absolute;
        top: 250px;
        right: 420px;
        opacity: 0;
      }

      .add-button {
        width: 35px;
        height: 44px;
        min-width: 41px;
        border: none;
        background: none;

        .circle {
          border: 1px solid $ckCharcoalGrey;
          border-radius: 50%;
          color: $ckCharcoalGrey;
          font-size: 12px;
        }
      }
    }
  }

  &.pen-width,
  &.highlighter-width,
  &.straight-line-width {
    md-input-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }

    .line-choice-container {
      height: 18rem;
    }

    .label {
      display: inline-block;
      color: $black;
      opacity: 0.8;
      font-size: 1.3rem;
    }

    .pixel {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      font-size: 1.3rem;
    }

    .input-section {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      padding: 0 10px;
      border-color: transparent;
    }

    .md-input {
      border-color: $ckGreenProgress;
    }

    input {
      height: 1.5rem;
      width: 60px;
      margin-right: 10px;
      padding: 0px;
    }

    input[type='number'] {
      height: 2.5rem;
      font-size: 1.3rem;
      max-width: 3rem;
      margin-right: 2px;
    }

    input[type='range'] {
      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      cursor: pointer;
      outline: none;
      overflow: hidden;
      border-radius: 16px;
    }

    input[type='range']::-webkit-slider-runnable-track {
      height: 1.5rem;
      background: $white;
      border-radius: 16px;
    }

    input[type='range']::-moz-range-track {
      height: 1.5rem;
      background: #ccc;
      border-radius: 16px;
    }

    input[type='range']::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      height: 1.5rem;
      width: 1.5rem;
      background-color: #fff;
      border-radius: 50%;
      border: 3px solid $ckGreenProgress;
      box-shadow: -407px 0 0 400px $ckGreenProgress;
    }

    input[type='range']::-moz-range-thumb {
      height: 1.5rem;
      width: 1.5rem;
      background-color: #fff;
      border-radius: 50%;
      border: 3px solid $ckGreenProgress;
      box-shadow: -407px 0 0 400px $ckGreenProgress;
    }

    md-button {
      min-width: auto;
    }
  }

  &.image-menu {
    width: 183px;
    font-size: $bodyCopy;

    .tool-button {
      width: 183px;
      padding: 15px;
    }
  }

  .request-help {
    @include transition(background-color, 300ms);

    &.help {
      background-color: $ckYellow;
      i {
        color: white;
      }
    }

    &.check {
      background-color: $ckGreen;
      i {
        color: white;
      }
    }

    &.unread-messages {
      i {
        color: $ckGreen;
      }
      .notification-circle {
        position: absolute;
        top: 11px;
        left: 29px;
        width: 0.5em;
        height: 0.5em;
        border-radius: 50%;
        background-color: $ckGreen;
      }
    }
  }
}

.mode-select-container {
  width: 60px;

  md-content {
    min-width: 60px;
  }

  @extend .ck-select-menu;
}

.fitb-pro {
  &.anchor {
    position: absolute;
    left: 8.2rem;
    margin-left: -25px;
    top: 0;
  }

  .pro-tag {
    padding: 0.3rem;
  }
}

.fitb-option {
  font-size: 1.3rem;
}

.font-family {
  font-size: medium;
}

.admin {
  height: 100vh;

  .content {
    overflow: auto;
    padding: 1em;

    .user-id {
      width: 20em;
    }

    .reset-email,
    .reset-new-email {
      width: 20em;
    }

    .section {
      padding: 1em 0;

      .organization {
        padding: 0 0 1em 1em;
      }

      .contract-delete {
        color: red;
      }

      .contract {
        padding: 0 0 0 1em;
      }
    }
  }
}

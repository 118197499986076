.import-google-rosters-dialog {
  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
    margin-bottom: 24px;
    padding: 0 60px;
  }

  md-list {
    border-top: 1px solid $dividerGrey;

    md-list-item.list-item {
      .md-button {
        color: $ckHeaderGrey;
      }
      box-shadow: none;
    }
  }

  .no-rosters-message {
    padding: 2em;
  }

  .actions {
    padding: 0 2.4rem 2.4rem 2.4rem;
    margin-top: 26px;

    .md-button {
      margin: 0 0 0 1.6rem;
    }
  }
}

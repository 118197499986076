.student-account-help-dialog {
  max-width: unset;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .content {
    padding: 0 60px 60px 60px;

    .dialog-title {
      @extend .classkick-header;
      margin-bottom: 32px;
      text-align: center;
    }

    .options {
      .option {
        .option-name {
          margin-bottom: 16px;

          .anchor {
            position: relative;
            height: 0;
            width: 100%;
          }

          .pro-tag {
            @extend .pro-tag;
            position: relative;
            bottom: 27px;
            left: 53px;
            width: 16px;
            padding: 3px;
          }
        }

        img.option-gif {
          width: 120px;
          height: 120px;
        }

        .option-details {
          padding: 24px 0 8px 16px;
          font-family: $fontRoundedBook;

          ul {
            padding-left: 16px;
            margin: 0;
          }

          li {
            min-height: 32px;
            width: 275px;
          }
        }

        .option-action .md-button {
          margin: 0;
        }
      }

      .spacer {
        width: 50px;
      }
    }
  }
}

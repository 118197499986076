.session {
  height: 100vh;

  .header {
    padding: 0;
    color: $ckAltGrey;
    font-size: 1.4rem;
    font-weight: bold;

    .ck-home-2023,
    .ck-contextualhelp,
    .ck-moreoptions {
      font-size: 2rem !important;
      color: $ckAltGrey !important;
    }

    .ck-gift {
      font-size: 2rem !important;
    }

    .ck-bell {
      font-size: 2.5rem !important;
    }

    .ck-forward-arrow-2023 {
      font-size: 1.6rem !important;
    }

    .md-button.home {
      @extend .bar-item;

      margin-left: 10px;
    }

    .assignment-name {
      margin-top: 2px;
    }

    help-inbox {
      padding: 0.8rem 0;
    }

    .nav-toggle {
      width: 44.4rem;

      &.show-preview-button {
        width: 65.5rem;

        &.co-teacher-nav {
          width: 57.5rem;
        }

        .md-button {
          width: 19rem;
        }
      }

      .background-border,
      .md-button,
      .md-button[disabled] {
        border-radius: 100px;
      }

      .background-border {
        width: inherit;
        border-color: $backgroundGrey;
        background-color: $backgroundGrey;
      }

      .md-button {
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 16px;
        color: $ckAltGrey;
        background-color: $backgroundGrey;
        width: 14.8rem;
        letter-spacing: 2px;
        font-weight: bold;
      }

      .md-button[disabled] {
        background-color: $ckAltGrey;
        border-color: $ckAltGrey;
        color: $white;
      }
    }

    .bar-item {
      width: 45px;
    }

    .new-ck-green-button {
      line-height: 2.8rem;
    }

    @media screen and (min-width: 1341px) {
      &.mobile {
        display: none;
      }
    }

    @media screen and (max-width: 1340px) {
      display: block;
      height: auto;
      padding: 0 16px 8px 16px;

      &.desktop {
        display: none;
      }

      .header-row {
        margin: 10px 0;
      }

      .md-button.home {
        margin-left: 0px;
      }

      .assignment-name {
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .nav-toggle {
        display: flex;
        width: 100%;

        .md-button {
          width: inherit;
          min-width: auto;
        }

        .student-preview {
          min-width: 15rem;
        }
      }

      .background-border {
        position: relative !important;
        margin-botton: 20px;
      }

      .right-side {
        help-inbox {
          margin: 10px 16px 10px 0;
          padding: 0;
          height: 4.3rem;
        }
      }
    }

    .export-grades-pro {
      &.anchor {
        position: absolute;
        margin-left: 14rem;
        top: 0.5rem;
        font-weight: normal;
        font-family: $fontRoundedBook;
      }

      .pro-tag {
        padding: 0.3rem;
      }
    }

    &.mobile {
      .export-grades-pro {
        &.anchor {
          top: 1.5rem;
        }
      }
    }
  }

  .config-bar {
    border-bottom: 1px solid $dividerGrey;
    height: 48px;
    padding: 0 0 0 3.2rem;

    &.shadow {
      box-shadow: $boxShadow;
      z-index: 11;
      border-bottom: none;
    }

    .md-button.class-code {
      color: $ckHeaderGrey;
      border: 2px solid $borderGrey;
      border-radius: 4px;
      margin-right: 20px;
      margin-left: 0;

      span {
        margin-right: 0.5rem;
      }

      .md-ripple-container {
        border-radius: 2px;
      }
    }

    .divider {
      height: 100%;
      width: 1px;
      background-color: $dividerGrey;
      margin-right: 20px;
    }

    .md-button.disabled-sort,
    .md-button.disabled-session-question {
      width: 160px;
      text-align: left;
    }

    .md-button.disabled-sort[disabled],
    .md-button.disabled-session-question[disabled],
    .md-button.class-code[disabled] {
      background-color: $disabledGrey;
      color: $lightText;
      text-transform: none;
      border: 2px solid $disabledGrey;
      border-radius: 4px;
      margin-right: 20px;
      margin-left: 0;

      i.ck-carrot,
      span {
        margin-top: 2px;
      }

      .md-ripple-container {
        border-radius: 2px;
      }
    }

    // Prevents 1px adjustment to select text that angular material library is doing
    md-input-container.md-input-has-value
      .md-select-value
      > span:not(.md-select-icon) {
      transform: none;
    }

    md-input-container.option {
      margin: 0 20px 0 0;
      padding: 0;

      md-select[disabled] .md-select-value {
        background-image: none;
        border-bottom-color: $disabledGrey !important;
      }
    }

    .md-button.bar-item {
      margin: 0;
      padding: 0;
      min-height: unset;
      min-width: 80px;

      &.filters,
      &.stickers,
      &.placing-sticker,
      &.bulk-update {
        padding: 0 30px;
        min-width: 50px;
        width: unset;
      }

      &.filters {
        margin: 0 2px;
        min-width: 55px;
      }

      &.stickers,
      &.placing-sticker,
      &.bulk-update {
        margin: 0 14px 0 0;
      }

      &.bulk-update {
        &.active {
          background-color: $ckAltGrey;
          color: $white;
        }
      }

      &.placing-sticker {
        font-family: $fontRoundedBook;
        background-color: $ckGreen;
        color: white;

        .image-container {
          width: 30px;
          height: 30px;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .text {
          margin-right: 10px;
        }

        .ck-close {
          margin-left: 14px;
        }
      }

      span {
        margin-left: 10px;
        text-transform: none;
      }
    }

    md-menu {
      margin-right: 14px;
    }
    .ck-star-filled {
      font-size: 1.7rem;
      color: $blueViolet;
    }
    .ck-star-unfilled {
      font-size: 1.7rem;
    }
    .ck-pin-clicked {
      font-size: 1.7rem;
      color: $blueViolet;
    }

    .ck-pin-unclicked {
      font-size: 1.7rem;
    }
  }
}

.filters-menu {
  max-height: none;

  md-list-item.filters-title,
  md-list-item.settings-title {
    max-height: 2.5em;
    min-height: 2.5em;
    padding: 0 16px;
    border-bottom: 1px solid $dividerGrey;

    > * {
      padding: 0;
      margin-top: 1px;
    }
  }

  md-list-item.settings-title {
    border-top: 1px solid $dividerGrey;
  }

  ck-switch {
    margin: 0;
    color: $ckHeaderGrey;
    font-family: $fontRoundedBook;
  }
}

.session-question-selector-menu {
  md-select-menu {
    max-height: none;

    md-content {
      max-height: 95vh;
      min-width: 100px;
    }
  }

  @extend .ck-select-menu;
}

.blank-slate {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  background-color: white;
}

toolbar-font-family-menu {
  .font-family-container {
    width: 600px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
  }

  .transparent-button a {
    padding: 15px 30px;
    justify-self: center;
    align-self: center;
  }

  .font-family-display {
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: none;
    color: $ckCharcoalGrey;
  }

  .selected {
    background-color: $backgroundGreen;
    padding: 8px 8px;
    border-radius: 2em;
  }
}

.choose-assignment-dialog {
  .bar-item {
    padding: 1.3rem;
  }

  .dialog-nav {
    height: 60px;
  }

  .dialog-header {
    text-align: center;
    height: 40px;
  }

  md-dialog-content {
    padding: 0;
    width: 440px;
    max-height: 30em;
    min-height: 4em;
  }

  .choose-assignment {
    margin-bottom: 30px;

    md-list {
      border-top: 1px solid $dividerGrey;
      overflow: auto;
    }

    md-list-item {
      background-color: white;
      border-bottom: 1px solid $dividerGrey;

      &.assignment-name {
        font-family: $fontRoundedBook;
        color: $ckHeaderGrey;

        .md-button {
          color: $ckHeaderGrey;
        }
      }

      .md-list-item-inner {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }
}

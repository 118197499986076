assignment-thumbnail {
  position: relative;
  background-color: white;
  height: 2008px;
  width: 1004px;
  overflow: hidden;
  margin: 0 auto;

  svg {
    height: 100%;
    width: 100%;
    background-color: transparent;
  }
}

.contributor-list {
  background-color: white;

  .no-users-message {
    font-family: $fontRoundedBook;
    color: $ckHeaderGrey;
  }
}

.contribution-history-item {
  background-color: white;

  .message {
    font-family: $fontRoundedBook;
    color: $ckHeaderGrey;
  }

  .user-identifier {
    font-size: $bodySub;
    color: $lightText;
    font-family: GothamRoundedBook;
  }

  i {
    color: $ckHeaderGrey;
  }
}

.submit-order {
  min-height: 100vh;
  width: 100vw;
  background: $ckGreen;
  background-image: url('../../../assets/images/scribs.png');
  background-repeat: no-repeat;
  background-position: center;

  .body {
    margin: 60px 0;
    width: 630px;
    max-width: 100%;
    background-color: white;
    border-radius: 10px;

    .body-header {
      height: 5.6em;
      margin-top: 20px;
    }

    .body-content {
      padding: 0 12rem 6rem 12rem;

      md-input-container.billing-name,
      md-input-container.billing-email,
      md-input-container.point-person,
      md-input-container.organization-name,
      md-input-container.name-on-card,
      md-input-container.zip,
      md-input-container.purchase-order {
        padding: 0;
        margin: 10px 0 0 0;

        &.billing-name {
          margin: 0;
        }

        &.organization-name .label {
          text-transform: capitalize;
        }
      }

      md-input-container.billing-name,
      md-input-container.point-person {
        .label {
          text-transform: capitalize;
        }

        .anchor {
          height: 0;
          width: 0;
          position: relative;
          top: 1px;

          .ck-contextualhelp {
            margin-right: 1px;
            cursor: pointer;
          }
        }
      }

      md-input-container.purchase-order {
        margin-bottom: 20px;
      }

      .address-details {
        md-input-container.city {
          margin-top: 10px;
          padding: 0;
        }

        md-autocomplete.state {
          margin: 10px 8px 0 8px;
          min-width: 50px;
        }

        md-autocomplete.country {
          margin-top: 10px;
        }
      }

      .separator {
        height: 2px;
        background-color: $dividerGrey;
        margin: 0 0 20px 0;
      }

      .payment-details {
        margin: 0 0 20px 0;

        .duration {
          font-size: $bodySub;
          margin-bottom: 10px;
        }

        .total {
          margin-left: 5px;
        }
      }

      .md-button {
        margin: 0;

        &.ck-primary {
          margin-top: 20px;
        }
      }

      .card-container {
        height: 80px;

        md-input-container.card {
          pointer-events: none;
          margin-top: 8px;
          padding-left: 0;
          padding-right: 0;

          input {
            position: absolute;
          }
          .error-messages {
            position: absolute;
            top: 32px;
          }
        }

        .card-element {
          position: relative;
          top: 1px;
          left: 4px;
          margin: 0 8px;
          transition: opacity 0.2s;
        }

        .ck-card-empty:not(.ck-card-focused) {
          opacity: 0;
        }
      }
    }
  }

  .error,
  .loading {
    margin-top: 8em;
    padding: 25px;
    background-color: white;
    border-radius: 10px;

    .loading-text,
    .error-text {
      padding: 0.5rem 0.5rem 0 0.5rem;
      font-family: $fontRoundedBook;
    }
  }
}

.help-section {
  height: 100vh;
  overflow: auto;

  .header {
    background-color: $ckGreen;
    padding: 0 2.4rem;
    border-bottom: $ckGreen;

    .logo {
      width: 120px;
    }

    .title {
      color: white;
      margin-left: 8px;
    }

    a {
      color: white;
      text-transform: none;
    }
  }

  .body {
    .body-container {
      width: 1000px;

      .breadcrumbs {
        height: 70px;

        a,
        span {
          text-transform: none;
          line-height: 1;
        }

        .ck-pagernext {
          margin: 0 8px;
          color: $disabledGrey;
        }
      }

      .search .article-autocomplete {
        width: 250px;
      }

      .divider {
        background-color: $dividerGrey;
        height: 2px;
        width: 100%;
      }

      .section-title {
        font-size: 2.7rem;
        margin: 40px 0 16px 0;
      }

      .articles {
        a {
          font-family: $fontRoundedBook;
          text-transform: none;
          margin-bottom: 8px;
        }
      }
    }
  }
}

md-dialog.camera-dialog {
  max-height: unset;
  max-width: unset;
  min-width: unset;

  .camera-dialog-header {
    height: 60px;

    .bar-item {
      width: 60px;

      .ck-close {
        color: $ckGreen;
      }
    }
  }

  .camera-dialog-footer {
    height: 60px;
  }

  .snap {
    height: 100%;
  }

  .unsupported,
  .blocked {
    padding-bottom: 60px;

    p {
      padding: 0 60px;
    }

    a {
      text-transform: none;
    }
  }

  .loading {
    min-width: unset;

    .ck-dialog-body {
      overflow: hidden;
      padding: 0;

      .spinner {
        margin: 20px;
      }
    }
  }
}

.move-assignment-dialog {
  min-width: inherit;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close,
    .ck-back {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
  }

  .body {
    overflow: hidden;
    width: 38em;
    padding: 1em;

    .body-header {
      width: 80%;
      border-bottom: 1px solid $dividerGrey;

      .folder {
        text-transform: capitalize;
        color: $ckHeaderGrey;
        margin: 0;
        border-radius: 0;
        font-family: GothamRoundedMedium;
        font-size: 13px;
        margin: 0.5em 0 0.5em 0em;
      }
    }

    md-list {
      width: 80%;
      min-height: 12em;
      overflow: auto;
      background-color: white;

      md-list-item {
        border-bottom: 1px solid $dividerGrey;

        span,
        i {
          font-family: $fontRoundedBook;
          font-size: 0.9em;
          color: $ckHeaderGrey;
          padding-right: 16px;
        }
      }

      md-list-item.selected {
        background-color: $ckHeaderGrey;

        span,
        i {
          color: white;
        }
      }
    }

    p {
      text-align: center;
    }
  }
}

.add-anon-student-dialog {
  width: 600px;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
  }

  p {
    font-family: $fontRoundedBook;
    text-align: center;
  }

  md-input-container {
    margin: 16px 60px 32px 60px;
  }

  .actions {
    padding: 0 2.4rem 2.4rem 2.4rem;
    margin-top: 26px;

    .md-button {
      margin: 0 0 0 1.6rem;
    }
  }
}

md-dialog.create-contract-students-dialog {
  max-height: 95%;
  max-width: 95%;
  min-width: unset;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close,
    .ck-back {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
  }

  .select,
  .add-or-edit-user,
  .import-csv {
    form {
      input {
        color: $ckGreen;
        text-shadow: 0 0 0 $ckHeaderGrey;
        -webkit-text-fill-color: transparent;
      }

      .error-messages {
        position: absolute;
        top: 32px;
        left: 12px;

        div {
          font-size: 0.8rem;
        }

        .add-student-error {
          color: $lightText;
          text-transform: uppercase;
          padding: 5px 5px;
        }
      }
    }
  }

  .review,
  .created {
    md-virtual-repeat-container {
      min-height: 40vh;

      md-list {
        background: white;
        color: $ckHeaderGrey;

        md-list-item.list-item {
          box-sizing: border-box;
          border-bottom: 1px solid $dividerGrey;
          box-shadow: none;
          font-family: $fontRoundedBook;

          .md-button {
            color: $ckHeaderGrey;
          }

          .md-list-item-inner {
            height: 48px;
          }

          .anchor {
            height: 0;
            position: absolute;

            .list-item-error-message {
              position: relative;
              top: 10px;
              color: $ckWarn;
            }

            .list-item-warning-message {
              position: relative;
              top: 10px;
              color: $lightText;
            }
          }
        }

        .error-message {
          position: absolute;
          top: 38px;
          pointer-events: none;
          text-transform: uppercase;
          color: $ckWarn;
          font-size: 7pt;
        }

        .empty-message {
          padding: 16px;
          letter-spacing: 0.0625em;
          color: $ckHeaderGrey;
        }
      }
      border-bottom: 1px solid $dividerGrey;
    }
  }

  .import-csv {
    width: 650px;

    .ck-dialog-title {
      margin-bottom: 24px;
    }

    p {
      margin: 13px 70px;
      font-family: $fontRoundedBook;
      text-align: center;

      span {
        font-family: $fontRoundedMedium;
      }

      &.sample {
        font-family: $fontRoundedMedium;

        a {
          text-transform: none;
        }
      }
    }

    .actions {
      padding: 0 2.4rem 2.4rem 2.4rem;
      margin-top: 32px;

      .md-button.ck-primary {
        margin: 0 0 0 1.6rem;
      }
    }
  }

  .select {
    max-width: 724px;
    padding: 0 60px 36px 60px;

    .select-contract {
      margin-top: 32px;

      md-input-container {
        width: 360px;
        margin: 16px 0 20px 0;
      }
    }

    .directions {
      margin-top: 32px;
      text-align: center;
    }

    .disclaimer {
      margin-top: 32px;
      text-align: center;
      font-family: $fontRoundedBook;
    }

    .create-options {
      width: 220px;
      margin: 0 2.5em;
      padding: 24px 16px 16px 16px;
      border-radius: 25px;
      outline: none;
      box-shadow: $boxShadowCard;
      cursor: pointer;

      .image-container {
        img {
          width: 120px;
        }
      }

      .message {
        @extend .classkick-header;
        margin: 1em 0;
        text-align: center;
        color: $ckGreen;
      }
    }

    .option {
      margin: 0 0 0 0.5em;
      padding: 0;

      .error-messages {
        top: 35px;
      }

      md-select.ng-invalid.ng-dirty .md-select-value {
        border: 1px solid $ckWarn;
      }
    }

    a {
      text-transform: none;
    }
  }

  .add-or-edit-user {
    width: 650px;

    .ck-dialog-title {
      margin-bottom: 36px;
    }

    form {
      md-input-container {
        margin: 16px 120px 20px 120px;
      }

      .first-name-input {
        &.add-spacing {
          margin-top: 25px;
        }
      }

      .actions {
        padding: 40px 24px 24px 24px;

        .md-button {
          margin: 0 0 0 1.6rem;
        }
      }
    }
  }

  .review {
    width: 780px;

    .ck-dialog-title {
      margin-bottom: 36px;
    }

    .first-name-col,
    .last-name-col,
    .id-col,
    .password-col {
      padding-right: 1em;
      min-width: 0;

      // HACK: max-width is set b/c I couldn't figure out how to get
      // the flex to work with white-space: nowrap;
      .truncate {
        min-width: 0;
        max-width: 28em;
      }
    }

    .remove-col {
      width: 60px;
      height: 60px;

      .md-button {
        height: 100%;
      }
    }

    .actions {
      padding: 0 2.4rem 2.4rem 2.4rem;
      margin-top: 26px;

      .md-button {
        margin: 0 0 0 1.6rem;
      }
    }
  }

  .created {
    width: 700px;

    .ck-dialog-title {
      margin-bottom: 24px;
    }

    .message {
      padding: 32px 60px 0 60px;
      text-align: center;
    }

    .actions {
      padding: 0 2.4rem 2.4rem 2.4rem;
      margin-top: 26px;

      .md-button {
        margin: 0 0 0 1.6rem;
      }
    }
  }

  .error-container {
    text-align: center;
    width: 650px;

    .actions {
      padding: 40px 24px 24px 24px;

      .md-button {
        margin: 0 0 0 1.6rem;
      }
    }
  }

  .remove-duplicates {
    p {
      font-family: $fontRoundedBook;
      text-align: center;
      margin: 24px 60px 0 60px;
    }

    .actions {
      padding: 0 2.4rem 2.4rem 2.4rem;
      margin-top: 60px;

      .md-button.ck-primary {
        margin: 0 0 0 1.6rem;
      }
    }
  }

  .loading {
    min-width: unset;

    .ck-dialog-body {
      overflow: hidden;
      padding: 0;

      .spinner {
        margin: 20px;
      }
    }
  }
}

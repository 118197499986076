.add-pro-student-dialog {
  max-height: 90%;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
    margin-bottom: 45px;
  }

  .roster-contract-options {
    display: block;
    margin: 10px 0px 16px 16px;
    max-width: 460px;

    .roster-dropdown-label {
      display: block;
    }

    .contract-organization {
      width: 460px;
    }
  }

  .loading {
    height: 0;
    overflow: visible;
  }

  .list-header {
    .search {
      i {
        width: 0;
        overflow: visible;
        position: relative;
        right: -0.75em;
        z-index: 1;
        pointer-events: none;
        color: $lightText;
      }
      md-input-container {
        margin-top: 0;
        input {
          padding-left: 2em;
          text-transform: none;
        }
      }
    }
  }

  md-virtual-repeat-container {
    height: calc(100vh - 21em);
    width: 750px;

    md-list {
      background: white;
      color: $ckHeaderGrey;

      md-list-item {
        box-sizing: border-box;
        border-bottom: 1px solid $dividerGrey;
        font-family: $fontRoundedBook;
      }
    }
    border-bottom: 1px solid $dividerGrey;
  }

  md-list-item md-checkbox {
    margin-right: 1em;
    margin-left: -2px;
  }

  .name-col {
    padding-right: 1em;
    min-width: 0;

    // HACK: max-width is set b/c I couldn't figure out how to get
    // the flex to work with white-space: nowrap;
    .truncate {
      min-width: 0;
      max-width: 28em;
    }
  }

  .login-col {
    width: 15em;
  }

  .actions {
    padding: 0 2.4rem 2.4rem 2.4rem;
    margin-top: 26px;

    .md-button {
      margin: 0 0 0 1.6rem;
    }
  }

  .empty-message {
    padding: 1em;
    text-align: center;
  }
}

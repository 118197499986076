audio-capture {
  position: relative;
  min-height: 390px;
  min-width: 200px;

  .md-button.recording-icon {
    font-size: 5em;
    height: unset;
  }

  .recording,
  .start,
  .audio-loading,
  .audio-message {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .message {
      height: 3em;
      @extend .classkick-header;
    }
  }

  .audio-loading {
  }

  .audio-message {
    text-align: center;

    p {
      padding-top: 0;
    }

    i {
      color: $disabledGrey;
    }
  }
}

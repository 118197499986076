.help-article {
  height: 100vh;
  overflow: auto;

  .header {
    background-color: $ckGreen;
    background-image: url('../../../assets/images/scribs.png');
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 2.4rem;
    border-bottom: $ckGreen;

    .logo {
      width: 120px;
    }

    .title {
      color: white;
      margin-left: 8px;
    }

    a {
      color: white;
      text-transform: none;
    }
  }

  .body {
    .body-container {
      width: 1000px;
      margin-bottom: 100px;

      .breadcrumbs {
        height: 70px;

        a {
          text-transform: none;
          line-height: 1;
        }

        .ck-pagernext {
          margin: 0 8px;
          color: $disabledGrey;
        }
      }

      .search .article-autocomplete {
        width: 250px;
      }

      .divider {
        background-color: $dividerGrey;
        height: 2px;
        width: 100%;
        margin-bottom: 32px;
      }

      .article-content {
        .article-title {
          @extend .classkick-header;
        }

        .article-body {
          font-family: $fontRoundedBook;
          margin-bottom: 32px;

          strong {
            font-family: $fontRoundedMedium;
            font-weight: unset;
          }
        }
      }

      .section-articles {
        width: 325px;
        margin-left: 32px;

        * {
          margin-bottom: 8px;
        }

        a {
          font-family: $fontRoundedBook;
          text-transform: none;
        }
      }

      .next-article {
        * {
          margin-bottom: 8px;
        }

        a {
          font-family: $fontRoundedBook;
          text-transform: none;
        }
      }
    }
  }

  .error,
  .loading {
    text-align: center;

    .loading-text,
    .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }
}

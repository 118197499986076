.signup-school {
  min-height: 100vh;
  width: 100vw;
  background: $ckGreen;
  background-image: url('../../../assets/images/scribs.png');
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.6;
  padding: 0 8px 5px 8px;

  .major-header {
    img {
      width: 16rem;
    }
    margin-top: 6rem;
    margin-bottom: 0.25em;
  }

  .body {
    width: 400px;
    max-width: 100%;
    background-color: white;
    border-radius: 10px;

    .body-header {
      height: 5em;
    }

    .body-content {
      padding: 0 2.4rem 2.4rem 2.4rem;

      .error-messages {
        div {
          font-size: 0.8rem;
        }
      }

      .org-options {
        padding: 0;
        margin: 0 0 12px 0;
      }

      md-autocomplete {
        margin-top: 12px;
      }

      md-input-container.school-name {
        margin-top: 0;
        padding: 0;
      }

      .md-button.ck-primary {
        margin: 20px 0 0 0;
      }
    }
  }

  .loading {
    margin-top: 8em;
    height: 100px;
    width: 100px;
    background-color: white;
    border-radius: 10px;
  }
}

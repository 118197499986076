.forgot-password {
  height: 100vh;
  width: 100vw;
  background: $ckGreen;
  background-image: url('../../../assets/images/scribs.png');
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 8px 20px 8px;
  overflow: auto;

  .major-header {
    img {
      width: 6em;
    }
    margin-top: 6rem;
    margin-bottom: 0.25em;
  }

  .body {
    width: 390px;
    max-width: 100%;
    background-color: white;
    border-radius: 10px;

    .body-header {
      height: 4em;
      width: 100%;

      div {
        height: 100%;
      }

      .end {
        width: 60px;
      }
    }

    .body-content {
      padding: 0 2.5em 20px 2.5em;

      p {
        margin: 10px 0 15px 0;
        text-align: center;
        color: $ckHeaderGrey;

        span.direction {
          font-family: $fontRoundedBook;
        }
      }

      md-input-container.reset-password-email {
        margin: 16px 0 20px 0;
        padding-right: 0;
        padding-left: 0;
      }

      .success-message {
        height: 10em;
        text-align: center;
      }

      .md-button.reset-password-button {
        margin: 10px 0px 0 0px;
      }
    }
  }

  .loading {
    margin-top: 8em;
    height: 100px;
    width: 100px;
    background-color: white;
    border-radius: 10px;
  }
}

.folder-name-dialog {
  width: 410px;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
  }

  .body {
    border-top: none;

    form {
      padding: 0;
    }

    .error-messages {
      position: absolute;
      top: 32px;
      left: 12px;

      div {
        font-size: 0.8rem;
      }
    }

    input {
      color: $ckGreen;
      text-shadow: 0px 0px 0px $ckHeaderGrey; /* change [input font] by this*/
      -webkit-text-fill-color: transparent;
    }

    md-input-container {
      width: 80%;
      margin: 16px 0 45px 0;
    }

    .actions {
      padding: 0 2.4rem 2.4rem 2.4rem;
      margin-top: 26px;

      .md-button {
        margin: 0 0 0 1.6rem;
      }
    }
  }
}

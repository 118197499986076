.invite-co-teacher-dialog {
  height: auto;
  max-height: 600px;
  max-width: 600px;
  overflow: auto;

  md-input-container.email-input-field {
    margin: 0 0 0 0;

    input {
      border: 1px solid $borderGrey;
      border-radius: 5px;
      margin-top: 5px;

      &:focus {
        border: 2px solid $ckGreenProgress;
      }

      &::placeholder {
        color: $lightText;
      }
    }
  }

  .invite-teacher {
    background-color: $ckBackgroundGrey;
    .ck-dialog-header {
      height: 30px;

      .ck-close {
        color: $ckGreen;
      }
    }

    .content {
      padding: 3rem 5rem 6rem 5rem;
      font-size: 1.2rem;
    }

    .ck-dialog-title {
      font-size: 2rem;
      font-weight: 800;
      text-align: center;
      align-self: center;
      padding: 0 3.5rem 0 3.5rem;
    }

    .ck-dialog-body {
      color: $slate;
      line-height: 1.5em;
      font-weight: 700;
      padding: 0 0 2rem 0;
    }

    .label,
    .ck-dialog-body {
      font-size: 1em;
      font-weight: 700;
      font-family: $fontRoundedBook;
    }

    .submit-button {
      margin-bottom: 1.5rem;
    }
  }

  .email-success {
    text-align: center;
    padding: 0 2.5rem 3.5rem 2.5rem;
    width: 550px;

    .ck-dialog-header {
      height: $teacherHeaderHeight;

      .ck-close {
        color: $ckGreen;
      }
    }

    .ck-dialog-title {
      @extend .classkick-header;
      text-align: center;
      padding: 0 60px;
    }

    i.ck-check {
      margin: 60px 0;
    }

    .email-success-message {
      @extend .classkick-header;
      font-family: $fontRoundedBook;
    }

    a {
      margin-top: 16px;
    }
  }

  .share-footer {
    position: fixed;
    height: 50px;
    background-color: $white;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    margin-top: 5px;
    padding-left: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .loading {
    .ck-dialog-body {
      overflow: hidden;
      padding: 0;

      .message {
        @extend .classkick-header;
        @extend .truncate;
        text-align: center;
        padding: 60px;
      }

      .spinner {
        margin: 60px 60px 60px 60px;
      }
    }
  }
}

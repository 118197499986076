.password-reset {
  height: 100vh;
  background: $ckGreen;
  background-image: url('../../../assets/images/scribs.png');
  background-repeat: no-repeat;
  background-position: center;

  .body {
    padding: 2em;
    background: white;
    border-radius: 10px;
  }

  .md-button {
    margin: 2em 0 0 0;
  }

  i {
    margin: 0.5em 0 0.1em 0;
  }

  p {
    font-family: $fontRoundedBook;
  }
}

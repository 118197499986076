.help-center {
  height: 100%;

  .hand {
    height: auto;
    padding: 10px 0;
    border-bottom: 1px solid $dividerGrey;

    .raise {
      padding: 0 50px;
      width: 100%;
      height: inherit;
      .md-button.help,
      .md-button.check {
        padding: 0 16px;

        i {
          width: 20px;
          margin-right: 3px;
        }
      }

      .help,
      .check,
      .chat {
        width: inherit;
      }

      .help {
        color: $ckYellow;
        border-color: $ckYellow;
      }
      .check {
        color: $ckGreen;
        border-color: $ckGreen;
      }

      .chat {
        color: $azureBlue;
        border-color: $azureBlue;
      }

      .ck-chat {
        margin-right: 5px;
      }
    }

    .lower {
      .md-button {
        padding: 8px 16px;
        height: inherit;
        line-height: 3.6rem;

        .circle {
          height: 43px;
          width: 43px;
          border-radius: 25px;

          i {
            color: white;
          }
        }
      }

      .md-button.help {
        color: $ckYellow;

        .circle {
          background-color: $ckYellow;
        }
      }

      .md-button.check {
        color: $ckGreen;

        .circle {
          background-color: $ckGreen;
        }
      }
    }

    .info {
      .circle {
        height: 43px;
        width: 43px;
        border-radius: 25px;
        background-color: $disabledGrey;

        i {
          color: white;
        }
      }
      .circle.help {
        background-color: $ckYellow;
      }

      .circle.check {
        background-color: $ckGreen;
      }
      .message {
        margin-top: 16px;
        color: $lightText;
      }
    }
  }

  .messages {
    chat-message:last-child {
      margin-bottom: 8px;
    }

    .no-messages {
      font-family: $fontRoundedBook;
      color: $lightText;
      height: 200px;
      padding: 16px;
      text-align: center;
    }
  }

  .message-input {
    border-top: 1px solid $dividerGrey;
    outline: none;

    md-input-container {
      margin: 8px 16px;
      padding: 0;

      textarea.md-input {
        max-height: 200px;
        overflow: auto;
        @extend .chat-message-text;
        padding: 0.7em 10px 0.7em 10px !important;
      }

      textarea[disabled] {
        cursor: pointer;
      }
    }

    md-input-container.md-input-focused::before {
      border-color: $ckGreen;
    }

    md-input-container.md-input-invalid::before {
      border-color: $ckWarn;
    }

    .glow {
      textarea {
        animation: glowing 1s ease-in-out;
        box-shadow: 0 0 20px $azureBlue;
        border: 1px solid $azureBlue;
      }
    }

    @keyframes glowing {
      from {
        border: 1px solid $azureBlue;
        box-shadow: 0 0 5px $azureBlue;
      }
      to {
        border: 1px solid $azureBlue;
        box-shadow: 0 0 20px $azureBlue;
      }
    }

    .error-messages {
      color: $ckWarn;

      div {
        margin-left: 16px;
        margin-top: -5px;
        font-size: 0.8rem;
        height: 25px;
      }
    }
  }
}

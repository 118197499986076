md-dialog.signup-dialog {
  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .ck-dialog-title {
    @extend .classkick-header;
    text-align: center;
  }

  .content {
    width: 550px;
    padding: 2em;

    p {
      font-family: $fontRoundedBook;
      text-align: center;
      margin: 0 0 24px 0;
    }

    .what-is-classkick {
      margin: 24px 0;

      i {
        margin-right: 8px;
      }
    }

    .actions {
      margin: 1em 0;
    }

    .login-button,
    .signup-button {
      width: 150px;
    }
  }
}

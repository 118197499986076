ck-switch {
  $switchHeight: 18px;
  $switchWidth: 36px;
  $switchTransition: 0.2s;
  $switchPadding: 2px;
  $switchCircleDiameter: calc(#{$switchHeight} - #{$switchPadding} * 2);

  font-family: $fontRoundedMedium;

  &.disabled {
    color: $disabledGrey;
    pointer-events: none;

    input:checked + .slider {
      background-color: $disabledGrey;
    }
  }

  .ck-switch-ctn {
    padding: 6px 0;
    cursor: pointer;
    outline: none;

    * {
      pointer-events: none;
    }
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: $switchWidth;
    min-width: $switchWidth;
    height: $switchHeight;
    margin-right: 8px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    display: none;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $disabledGrey;
    -webkit-transition: $switchTransition;
    transition: $switchTransition;
    border-radius: $switchHeight;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: $switchCircleDiameter; // 26px;
    width: $switchCircleDiameter; // 26px;
    left: $switchPadding; // 4px;
    bottom: $switchPadding; // 4px;
    background-color: white;
    -webkit-transition: $switchTransition;
    transition: $switchTransition;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  input:checked + .slider {
    background-color: $ckGreen;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  $translateX: 18px;
  // the transform is equal to the width minus the circle diameter or radius
  input:checked + .slider:before {
    -webkit-transform: translateX($translateX);
    -ms-transform: translateX($translateX);
    transform: translateX($translateX);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }
}

.select-peers-dialog {
  max-width: 600px;
  padding: 32px;
  border-radius: 12px;

  md-checkbox {
    .md-icon {
      transform: scale(1.2);
      border-radius: 8px;
      border: 1px solid $slate;
    }

    &.md-checked .md-icon {
      background-color: $ckGreenProgress;
      border: 1px solid $ckGreenProgress;

      &:after {
        margin-left: 2px;
        margin-top: 1px;
        transform: rotate(29deg);
        border-radius: 1px;
        border-width: 2.5px;
        left: 3.5px;
      }
    }
  }

  .checkbox-instructions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .instructions {
      text-transform: uppercase;
      font-family: $fontRoundedBold;
      font-size: 10px;
      line-height: 16px;
      letter-spacing: 2px;
      padding-right: 12px;

      span {
        position: relative;
        bottom: 2px;
      }

      div {
        display: inline-block;
        height: 24px;
        width: 24px;
        background-color: $backgroundGrey;
        border-radius: 8px;
        color: $slate;

        &.email {
          position: relative;
          top: 6px;

          i {
            font-size: 24px;
          }
        }

        &.return {
          position: relative;
          bottom: 3px;

          i {
            font-size: 14px;
            position: relative;
            left: 3px;
            top: 5px;
          }
        }
      }
    }
  }

  .header {
    padding-bottom: 32px;
    border-bottom: none;
    display: flex;
    height: 48px;

    .header-title {
      display: flex;
      align-items: center;
      font-size: 24px;
      font-family: $fontRoundedBold;
      color: $ckAltGrey;
    }

    .close {
      cursor: pointer;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      &:hover {
        i {
          color: $ckGreenProgress;
        }
      }

      i {
        color: $ckHeaderGrey;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  .search-input {
    md-input-container {
      width: 100%;

      input {
        padding-left: 48px;
        height: 48px;
        border-radius: 8px;
        border: 0.5px solid;

        &:focus {
          border: 2px solid $ckGreenProgress;
        }

        &.error {
          border: 2px solid $ckWarn;
        }
      }
    }

    div {
      i {
        font-size: 25px;
        top: 12.5px !important;
        left: 15px !important;
      }
    }

    .result-count {
      position: absolute;
      top: 14px;
      left: 428px;
      z-index: 1;
      height: 16px;
      width: max-content;
      background-color: $veryLightGrey;
      color: $ckAltGrey;
      border-radius: 100px;
      font-family: $fontRoundedBook;
      font-weight: 800;
      font-size: 10px;
      letter-spacing: 2px;
      text-align: center;
      padding: 6px 8px 0px 8px;
      text-transform: uppercase;
    }
  }

  .select-all-checkbox {
    font-family: $fontRoundedBold;
    margin: 0px 0px 0px 15px;

    &.dashmark {
      .md-container {
        .md-icon {
          background-color: $ckGreenProgress;
          border: 1px solid $ckGreenProgress;

          &:after {
            background-color: white;
            border: white;
            box-sizing: border-box;
            transform: rotate(90deg);
            position: absolute;
            left: 5.75px;
            top: 3.22222px;
            display: table;
            width: 2.66667px;
            height: 10.33333px;
            border-width: 2px;
            border-style: solid;
            border-top: 0;
            border-left: 0;
            content: '';
            margin-left: 2px;
            margin-top: 1px;
          }
        }
      }
    }

    .md-label {
      height: 48px;
      margin-left: 32px;
      letter-spacing: 2px;
      font-size: 10px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
    }
  }

  .peer-container {
    height: 120px;
    border: 0.6px solid $slate;
    border-radius: 12px;
    margin-bottom: 32px;

    &.error {
      border: 2px solid $ckWarn;
      margin-bottom: 0px;
    }

    .peers-list {
      background-color: transparent;

      .loading-spinner {
        display: flex;
        justify-content: center;
        font-size: 35px;
        margin: 8%;
      }

      .peer-checkbox {
        margin-right: 10px;
      }

      .checkbox-label {
        font-size: 1.1em;
        font-family: $fontRoundedBook;
        max-height: 48px;
        color: $ckAltGrey;
      }
    }

    .md-list-item-inner {
      height: 48px;
    }

    .md-proxy-focus {
      .md-no-style {
        padding: 0px 12px;
      }
    }
  }

  .submit-container {
    display: flex;
    flex-direction: row-reverse;

    .submit-button {
      font-size: 16px;
      font-weight: 800;
      height: 48px;
      color: $ckAltGrey;
      margin: 0;
    }
  }

  .error-container {
    div {
      display: flex;
      justify-content: flex-end;

      i:hover {
        cursor: pointer;
      }
    }

    h3 {
      text-align: center;
    }
  }

  .error-messages {
    padding: 8px 12px 0px 12px;
    line-height: 16px;
    font-size: 10px;

    &.selection-error {
      margin-bottom: 32px;
    }
  }
}

.help-coaches {
  height: 100vh;
  overflow: auto;

  .header {
    background-color: $ckGreen;
    padding: 0 2.4rem;
    border-bottom: $ckGreen;

    .logo {
      width: 120px;
    }

    .title {
      color: white;
      margin-left: 8px;
    }

    a {
      color: white;
      text-transform: none;
    }
  }

  .body-top {
    .body-top-container {
      width: 1000px;

      .breadcrumbs {
        height: 70px;

        a,
        span {
          text-transform: none;
          line-height: 1;
        }

        .ck-pagernext {
          margin: 0 8px;
          color: $disabledGrey;
        }
      }

      .search .article-autocomplete {
        width: 250px;
      }

      .divider {
        background-color: $dividerGrey;
        height: 2px;
        width: 100%;
      }
    }
  }

  .connect-with-teacher {
    .connect-with-teacher-container {
      width: 1000px;
    }

    .category-title {
      font-size: 2.7rem;
      margin: 40px 0 16px 0;
    }

    p {
      font-family: $fontRoundedBook;
    }

    md-grid-tile {
      background-color: white;
      width: 205px;
      height: 330px;

      .image-container {
        width: 205px;

        img {
          border-radius: 50%;
          width: 200px;
        }
      }

      .name {
        margin-top: 16px;
      }

      .details {
        color: $lightText;
        white-space: nowrap;
        text-align: center;
      }

      .handle {
        font-family: $fontRoundedBook;
        white-space: nowrap;

        span.link {
          outline: none;
          cursor: pointer;
        }
      }

      a.public-assignments-link {
        text-transform: none;
        font-family: $fontRoundedBook;
      }
    }
  }
}

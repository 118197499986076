.reserve-lock-order-dialog {
  min-width: unset;
  max-height: unset;

  .loading,
  .error {
    padding-bottom: 36px;
  }

  .reserve-order,
  .loading,
  .error {
    max-height: 100vh;

    .ck-dialog-body {
      md-input-container {
        padding-left: 0;
        padding-right: 0;
        margin-left: 76px;
        margin-right: 76px;
        margin-top: 0;

        textarea.md-input {
          line-height: normal;
          padding-top: 8px !important;
          overflow-y: scroll;
        }
      }

      .error-messages {
        left: 12px;

        div {
          font-size: 0.8rem;
        }
      }

      .md-button.ck-primary {
        margin: 20px 0 0 0;
      }
    }
  }

  &.reserve-order-main {
    width: 600px;
    border-radius: 12px;

    .ck-dialog-header {
      .reserve-order-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 0 32px;

        .close {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          align-self: flex-start;

          &:hover {
            i {
              color: $ckGreenProgress;
            }
          }

          i {
            color: $ckAltGrey;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

      .reserve-instructions {
        padding: 0 4rem;
        text-align: center;
        color: $ckAltGrey;

        .title {
          display: flex;
          align-items: center;
          font-size: 24px;
          font-family: $fontRoundedBold;
          text-align: center;
          max-width: 43rem;
          padding: 0 1.5rem;
          font-size: 1.9rem;
          margin-bottom: 1.2rem;
        }

        .subtext {
          font-size: 1.4rem;
        }
      }

      &.forward {
        @extend .referral;
        margin: 3.2rem 0 2.3rem 0;

        .reserve-order-title {
          @extend .referral-title;
        }
      }
    }

    .ck-dialog-body {
      &.reserve {
        margin: 0 32px 32px 32px;

        .error-messages {
          margin-left: 80px;

          @media screen and (max-width: 750px) {
            margin-left: 0px;
            left: 0px;
          }
        }

        .submit-reserve-price {
          width: auto;
          text-transform: uppercase;
          font-weight: 1000;
          height: 4.8rem;
          margin-top: 17px !important;
          background-color: $ckGreenProgress;
          color: $ckAltGrey;
          font-size: 1.6rem;
          padding: 1.6rem 2.4rem;
        }

        .reserve-order-track {
          @extend .submit-reserve-price;
          background-color: $ckGreen;
          color: $white;
          border-radius: 8px;
          white-space: normal;

          .md-ripple-container {
            border-radius: 8px;
            background-color: transparent;
          }

          &:hover {
            background:
              linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
              $azureBlue;
          }

          &:active {
            background-color: $ckAltGrey;
            color: $white;
          }

          @media screen and (max-width: 590px) {
            height: auto;
            padding: 1.4rem 2.4rem;
          }
        }

        .reserve-container {
          margin: 8px 0px;

          span {
            left: 0px;
            top: 0px;
            border-radius: 0px;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 2px;
            text-transform: uppercase;
            width: 70px;
            text-align: end;

            @media screen and (max-width: 750px) {
              text-align: start;
            }
          }

          .ck-building {
            font-size: 2.2rem;
            top: 14px;
            left: 98px;
            position: absolute;
          }

          input,
          textarea {
            border: 0.5px solid $ckHeaderGrey;
            border-radius: 8px;
            padding: 12px;
            font-size: 1.4rem;
            height: 4.8rem;

            &:focus {
              border: 2px solid $ckGreenProgress;
            }
          }

          input[name='fromInput'],
          input[name='toInput'] {
            color: $ckAltGrey;
            font-family: $fontRoundedBold;
            font-size: 1.4rem;

            &::placeholder {
              color: $slate;
            }

            &:focus {
              &::placeholder {
                color: $ckAltGrey;
              }
            }
          }

          textarea[name='bodyInput'] {
            line-height: 2rem;
            overflow: auto;
          }
        }
      }
    }

    md-input-container,
    .md-input {
      color: $ckAltGrey;
    }

    input[name='subjectInput'] {
      font-weight: bold;
    }
  }
}

.assignment {
  height: 100vh;

  .loading,
  .error {
    text-align: center;

    .loading-text,
    .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }

  .header {
    .md-button.bar-item {
      width: unset;
      min-width: unset;
      margin: 0 15px 0 0;
      min-height: unset;

      &.back {
        width: 60px;
        min-width: 60px;
      }

      span {
        margin: 3px 0 0 10px;
        height: 100%;
        text-transform: none;
      }
    }

    i {
      padding-right: 0.5em;
    }
    .logo {
      margin-left: 24px;
      height: 30px;
    }
    .logo:hover {
      cursor: pointer;
    }
    .logo:focus {
      outline: none;
    }

    .md-button.share {
      min-width: 32px;
      min-height: 32px;

      .ck.fa.ck-share {
        color: $ckHeaderGrey;
        padding: 0;
      }
    }
  }
  .assignment-container {
    overflow: scroll;

    .assignment-metadata {
      padding: 1em 5em 1em;
      box-shadow: $boxShadow;
      z-index: 5;

      .assignment-name-title {
        margin: 8px 0;
      }

      .assignment-name {
        @extend .major-header;
        margin-bottom: 16px;
      }

      .assignment-description {
        font-family: $fontRoundedBook;
        padding-bottom: 1em;
        white-space: pre-wrap;
        width: 75%;
      }

      @media (max-width: 959px) {
        .assignment-description {
          width: 100%;
        }
      }

      hr {
        margin: 0;
        width: calc(100vw - 10em);
        border-top: 0;
        border-style: solid;
        border-color: $disabledGrey;
      }

      .assignment-description-title {
        margin: 16px 0 8px 0;
      }

      .assignment-tags {
        margin-bottom: 8px;

        .subject,
        .grade {
          span {
            font-family: $fontRoundedBook;
          }
        }

        .subject {
          margin-right: 24px;
        }
      }
    }

    .questions {
      border-style: solid;
      border-width: 0 0 1px 0;
      border-color: $disabledGrey;

      background: $backgroundGrey;

      .section-header {
        @include flex-row(flex-start, space-between);
        padding: 2em 4.5em 0em;
      }

      .questions-container {
        padding: 0 4em 2em 4em;
        @include flex-row(center, flex-start, wrap);

        & > * {
          display: inline-block;
          width: 20em;
          height: 15em;
          margin: 1em 1em;
        }

        .question {
          position: relative;
          background: white;
          box-shadow: $boxShadowCard;
          outline: none;

          cursor: pointer;

          & > * {
            position: absolute;
          }

          .question-mask {
            @include flex-row(center, space-between);

            background-color: $whiteMask;
            width: 100%;
            height: 45px;
            bottom: 0;
            left: 0;

            & > * {
              @include flex-row(center, center);
              width: 45px;
              height: 45px;
            }

            .menu-button {
              .md-button {
                border-radius: 0;
                width: 45px;
                height: 45px;
                min-width: 45px;
                padding: 0;
                margin: 0;
                font-size: 125%;

                .md-ripple-container {
                  border-radius: 0;
                }
              }
            }
          }

          assignment-sheet {
            width: $publicAssignmentSheetWidth;
            height: $publicAssignmentSheetHeight;
            overflow: hidden;
            background-color: white;

            /* Our canvas is 2:1, so calculate height based on width */
            svg {
              height: calc(2 * #{$publicAssignmentSheetWidth});
            }
          }
        }

        .add-question {
          padding: 0;
          font-size: inherit;

          border-style: dashed;
          border-width: 2px;
          border-color: $ckGreen;
          border-radius: 0;

          .md-ripple-container {
            border-radius: 0;
          }
        }
      }
    }
  }
}

.shared-work-question {
  height: 100vh;
  width: 100vw;

  .assignment-question-header {
    background-color: $ckHeaderGrey;
    color: white;
    font-size: 1.8rem;

    .md-button.bar-item {
      color: white;
    }

    .title {
      color: white;
      @extend .classkick-header;
    }

    help-inbox help-request-indicator i.ck-hand {
      color: white;
    }
  }

  .error {
    text-align: center;
    font-size: 1.5em;
  }
}

.select-bulk-update-menu {
  font-size: $bodyCopy;
  box-shadow: $boxShadowCard;
  background-color: white;

  .select-bulk-update-header {
    border-bottom: 1px solid $dividerGrey;
    font-family: $fontRoundedMedium;
    color: $ckHeaderGrey;
    padding: 0 0 0 16px;
    height: 48px;
    justify-content: space-between;

    .button-copy {
      padding: 0 5px;
    }
  }

  md-list {
    max-height: 408px;
    overflow: auto;
    background-color: white;

    md-list-item.bulk-update {
      height: 48px;
      border-bottom: 1px solid $dividerGrey;

      .md-button.md-no-style {
        padding-right: 16px;
      }

      .text {
        z-index: 100;
        outline: none;
        color: $ckHeaderGrey;
      }

      &.selected {
        background-color: $ckHeaderGrey;

        .text,
        .score,
        .ck-edit {
          color: white;
        }
      }
    }
  }
}

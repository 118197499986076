.admin-invites-teachers-dialog {
  min-height: unset;
  min-width: unset;
  max-width: 600px;
  max-height: 650px;

  md-checkbox.md-checked .md-icon {
    background-color: $ckGreen;
  }

  .ck-dialog-body {
    min-height: 300px;
    overflow-x: hidden;
  }

  .admin-invite-footer {
    position: fixed;
    height: 50px;
    background-color: $white;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    border-top: 1px solid $lightText;

    .ck-primary-span {
      font-size: 1.1rem;
      font-weight: 100;
      color: $ckCharcoalGrey;
      margin: 0 0 0 30px;

      a {
        text-transform: none;
      }
    }
  }

  .loading,
  .error {
    width: 500px;

    .ck-dialog-header {
      margin-top: 20px;
    }

    .ck-dialog-body {
      overflow: hidden;
      padding: 0;

      .spinner {
        margin: 60px 0 0 0;
      }
    }
  }

  .no-teachers-message {
    line-height: 20px;
    font-size: 1.3rem;
    width: 550px;
    margin: 20px;
    padding: 10px;
    background-color: $referralGreen;
    color: $ckHeaderGrey;
    border-radius: 5px;
    text-align: center;
  }

  .error-messages {
    line-height: 20px;
    font-size: 14px;

    &.selection-error {
      margin-bottom: 32px;
    }
  }

  .subject {
    padding: 10px 10px 10px 10px;
  }

  .contract-options {
    justify-content: center;
    font-size: 1.3rem;
    text-align: end;

    .contract-options-dropdown {
      margin: 2.5rem 1rem 0 0;
    }
  }

  .select-all-checkbox {
    font-family: $fontRoundedBold;
    margin: 0px 0px 10px 25px;
    width: 250px;

    .md-label {
      height: 48px;
      font-size: 1.3rem;
      display: flex;
      align-items: center;
    }
  }

  .invite-teachers-email {
    margin-top: 10px;

    .invite {
      margin: 1.2rem 0 0 0;
      color: $ckAltGrey;

      .invite-title {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        padding: 0 32px;

        .close {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start;

          &:hover {
            i {
              color: $ckGreenProgress;
            }
          }

          i {
            color: $ckAltGrey;
          }
        }

        .title {
          @extend .classkick-header;
          font-weight: 600;
          text-align: center;
          margin: 0 116px;
        }
      }
    }

    .invite-teachers-section {
      margin: 0 32px 32px 32px;

      .error-messages {
        margin-left: 90px;

        @media screen and (max-width: 750px) {
          margin-left: 0px;
          left: 0px;
        }
      }

      .copy-invite {
        margin: 20px 20px 20px 30px;
        background-color: $veryLightGrey;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        text-transform: uppercase;
        color: $ckAltGrey;
        font-size: 1rem;
        line-height: 1.6rem;
        font-weight: 600;
        cursor: pointer;

        &:hover {
          color: $ckGreenProgress;

          &:active {
            background-color: $ckAltGrey;
            color: $white;
          }
        }

        .ck-new-copy-file {
          margin-right: 0.5rem;
          font-size: 1rem;
        }
      }

      .invite-teachers-container {
        padding-left: 0;
        padding-right: 0;
        color: $ckAltGrey;
        margin: 0px 0px;

        span {
          font-size: 1.2rem;
          padding-bottom: 10px;

          @media screen and (max-width: 750px) {
            text-align: start;
          }
        }

        .clip-board {
          text-underline-offset: 2px;
          cursor: pointer;
        }

        input,
        textarea {
          border: 1px solid $borderGrey;
          border-radius: 5px;
          padding: 12px;
          font-size: 1.2rem;

          &:focus {
            border: 2px solid $ckGreenProgress;
          }

          &::placeholder {
            color: $lightText;
          }
        }

        .email-body {
          padding: 12px !important;
        }
      }

      textarea.md-input {
        line-height: 1.8rem;
        padding-top: 8px !important;
        overflow-y: scroll;
        min-height: 250px;
      }

      .md-button.ck-primary {
        color: white;
        margin-right: 30px;
      }
    }
  }
}

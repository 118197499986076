.metric-list {
  height: 100vh;

  .header {
    md-select {
      width: 12em;
    }
  }
  .email-admin {
    @extend .referral-button-assignment;
    background-color: $azureBlue;
    color: $white;
    margin: 18px;
    padding: 12px;

    .md-ripple-container {
      border-radius: 8px;
      background-color: transparent;
    }

    .ck-paper-airplane {
      margin-right: 13px;
      font-size: 2.2rem;
    }

    &:hover,
    &:focus {
      background:
        linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
        $azureBlue;
    }

    &:active {
      background-color: $ckAltGrey;
    }
  }

  .overview {
    overflow: auto;

    .overview-header {
      margin: 1em 1em 1em 1.4em;
    }
    .overview-list {
      list-style: none;
      margin-left: 2em;
      margin-right: 4em;
      padding: 0;

      div {
        font-family: $fontRoundedBold;
        font-size: 1.5em;
        margin-left: 1.3em;
        background: linear-gradient(
          to right,
          $white,
          $ckGradientGreen,
          $ckGradientGreen2
        );
        height: 40%;
        border-radius: 7px;
        margin-bottom: 3px;
      }
    }
    .no-metrics {
      margin-left: 1.3em;
    }
  }
  .organization {
    border-bottom: 1px solid #eeeeef;

    md-select {
      margin: 1.3em 1.8em;
      width: 15.7em;
    }
  }
  .error,
  .loading {
    text-align: center;

    .loading-text,
    .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }
}

.select-sticker-menu {
  font-size: $bodyCopy;
  box-shadow: $boxShadowCard;
  background-color: white;

  .select-sticker-header {
    border-bottom: 1px solid $dividerGrey;
    font-family: $fontRoundedMedium;
    color: $ckHeaderGrey;
    padding: 0 0 0 16px;
    height: 48px;
  }

  md-list {
    // 408px is 8 1/2 rows at 48px per row
    max-height: 408px;
    overflow: auto;
    background-color: white;
    width: 320px;

    md-list-item.sticker {
      height: 48px;
      border-bottom: 1px solid $dividerGrey;

      &.add-new-sticker {
        .md-list-item-inner {
          text-transform: uppercase;
          font-family: $fontRoundedMedium;
        }
      }

      .md-button.md-no-style {
        padding-right: 0;
      }

      .img-container {
        width: 30px;
        height: 30px;

        margin-right: 0.6em;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .text,
      .score {
        color: $ckHeaderGrey;
      }

      .text {
        z-index: 100;
        outline: none;
      }

      .score {
        margin-right: 19px;
      }

      .edit {
        height: inherit;

        .md-button.bar-item {
          height: 48px;
          width: 48px;
          padding-left: 4px;
          padding-right: 4px;
        }
      }

      &.selected {
        background-color: $ckHeaderGrey;

        .text,
        .score,
        .ck-edit {
          color: white;
        }
      }
    }

    md-list-item.empty-message {
      color: $lightText;
      font-family: $fontRoundedBook;
    }
  }
}

.update-user-school-dialog {
  min-width: unset;
  max-height: unset;

  .update-user-school {
    .ck-dialog-header {
      height: $teacherHeaderHeight;

      .ck-close,
      .ck-back {
        color: $ckGreen;
      }
    }

    .ck-dialog-body {
      max-width: 500px;
      min-width: 500px;
      margin: 0 60px 60px 60px;

      .ck-dialog-title {
        @extend .classkick-header;
        text-align: center;
        margin-bottom: 50px;
      }

      p {
        margin: 0 0 50px 0;
        font-family: $fontRoundedBook;
        text-align: center;
      }

      md-input-container.school-name {
        margin: 0 0 6px 0;
        padding: 0;
      }

      .org-options {
        padding: 0;
        margin: 0 0 6px 0;
      }

      md-autocomplete {
        md-progress-linear {
          display: none;
        }

        .loading-spinner {
          position: relative;
          height: 0;
          width: 97%;
          bottom: 23px;
          text-align: right;
        }
      }

      md-autocomplete[md-floating-label] md-autocomplete-wrap {
        width: 100%;
      }

      .md-button.ck-primary {
        margin: 20px 0 0 0;
      }
    }
  }

  .loading {
    width: 100px;
    height: 100px;
  }
}

md-dialog.share-with-student-dialog {
  max-width: 900px;
  max-height: 96%;

  .ck-dialog-header {
    height: $teacherHeaderHeight;

    .ck-close {
      color: $ckGreen;
    }
  }

  .content {
    padding: 0 100px 80px 100px;
    width: 800px;
    text-align: center;

    .dialog-title {
      @extend .classkick-header;
      margin-bottom: 65px;
    }

    .welcome {
      @extend .classkick-header;
      margin-bottom: 65px;
      font-size: 50px;
      font-weight: bold;
    }

    .code {
      font-size: 20px;
      margin-bottom: 20px;
    }

    .login-directions {
      margin-bottom: 8px;

      .account-type {
        text-transform: none;
      }
    }

    .class-code {
      font-family: $fontRoundedMedium;
      font-size: 12.8rem;
      white-space: nowrap;
      margin-bottom: 65px;
      line-height: 130px;
    }

    .share-link-directions {
      margin-bottom: 16px;
    }

    .link-input {
      width: 100%;
      position: relative;

      .submit-button {
        margin: 0 0 0 18px;
      }
      .submit-button-copy-link {
        margin: 0 0 0 18px;
        background-color: $veryLightGrey;
        color: $ckAltGrey;
      }

      .spinner {
        text-align: center;
      }

      .readonly-link {
        text-overflow: ellipsis;
      }

      md-input-container {
        margin: 0;
        padding-left: 0;
        padding-top: 0;

        input {
          font-family: $fontRoundedBook;
        }
      }

      .share-to-classroom {
        margin-left: 20px;
        height: 32px;
        width: 32px;
      }
    }

    .welcome-link-input {
      gap: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .welcome-submit-button {
        width: 250px;
        margin: 0 auto;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .welcome-submit-button-copy-link {
        width: 250px;
        margin: 0 auto;
        background-color: $veryLightGrey;
        color: $ckAltGrey;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .steps {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      position: relative;
      height: 150px;
    }

    .step {
      flex: 1;
      padding: 20px;
      border-radius: 12px;
      text-align: center;
      position: relative;

      .step-badge {
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        font-weight: bold;
        font-family: $fontRoundedMedium;
        padding: 5px 15px;
        border-radius: 30px;
        font-size: 27px;
        margin-bottom: 20px;
      }

      p {
        font-weight: 350;
        font-family: $fontRoundedMedium;
      }
    }

    /* Vertical line */
    .vertical-line {
      width: 2px;
      background-color: $borderGrey;
      position: absolute;
      top: 1%;
      bottom: 30%;
    }

    .subtext {
      font-size: 14px;
      color: $ckAltGrey;
      margin-bottom: 15px;
    }
  }
}

.user-notifications-list {
  min-height: 100vh;

  .list-header {
    .col {
      &.notification-text {
        width: 100%;
      }

      &.received {
        width: 100%;
        padding-left: 26px;
      }
    }
  }

  md-list {
    min-height: $teacherListHeight;
    max-height: $teacherListHeight;
    overflow: auto;

    md-list-item {
      .anchor {
        position: relative;
        height: 0;
        width: 0;
        bottom: 0.5rem;
        right: 0.5rem;
      }

      .indicator-circle.seen {
        visibility: hidden;
      }

      .col {
        &.notification-text {
          .truncate {
            text-overflow: ellipsis;
            display: block;
          }
        }

        &.received {
          padding-left: 0px;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .loading,
  .error {
    text-align: center;
    height: 100%;

    .loading-text,
    .error-text {
      padding: 0.5rem;
      font-family: $fontRoundedBook;
    }
  }
}

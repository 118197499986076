toolbar-emoji-menu {
  .emojiContainer {
    width: 400px;
    height: 400px;
  }

  .transparent-button a {
    font-size: $bodySub;
    padding: 15px 30px;
  }

  .emojiDisplay {
    font-size: 24px;
  }
}

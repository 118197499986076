.pro-trial-overlay-conversion {
  box-sizing: border-box;
  color: $ckAltGrey;
  max-width: 60rem;
  width: 60rem;
  box-shadow:
    0px 12px 16px rgba(0, 0, 0, 0.12),
    0px 16px 24px rgba(0, 0, 0, 0.08),
    0px 24px 32px rgba(0, 0, 0, 0.08),
    0px 32px 40px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  padding: 32px;

  .dialog-header {
    font-family: $fontRoundedBold;
    font-size: 2.4rem;
    line-height: 3.2rem;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0.8rem 0 4rem 0;

    .header-title {
      max-width: 33.4rem;
    }

    .header-countdown {
      color: $ckWarn;

      &.countdown {
        text-transform: uppercase;
      }
    }

    .close {
      margin-right: 13px;
      cursor: pointer;
      &:hover {
        i {
          color: $ckGreenProgress;
        }
      }

      .ck-close {
        font-size: 1.4rem;
        color: $ckAltGrey;
      }
    }
  }

  .body {
    font-family: $fontRoundedBook;
    font-size: 1.4rem;
    box-sizing: border-box;
    padding: 4px 12px 0px 12px;
    border-radius: 8px;
    margin-bottom: 36px;
  }

  .body-footer {
    .quote-button {
      @extend .rectangle-button;
      @extend .white-button;
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: 2px;
      box-shadow: none;
      color: $ckAltGrey;
      background-color: $veryLightGrey;

      &:hover {
        background-color: $veryLightGrey;
      }

      &:active,
      &:focus {
        color: $white;
        background-color: $ckAltGrey;
      }
    }

    .email-admin {
      @extend .rectangle-button;
      @extend .blue-button;

      .ck-paper-airplane {
        margin-right: 13px;
        font-size: 1.9rem;
      }
    }
  }
}

.create-order-dialog {
  min-width: unset;
  max-height: unset;

  .identify-organization,
  .add-organization,
  .identify-person {
    .ck-dialog-header {
      height: $teacherHeaderHeight;

      .ck-close,
      .ck-back {
        color: $ckGreen;
      }
    }

    .ck-dialog-body {
      max-width: 500px;
      min-width: 500px;
      margin: 0 60px 60px 60px;

      .ck-dialog-title {
        @extend .classkick-header;
        text-align: center;
        margin-bottom: 15px;
        text-transform: capitalize;
      }

      p {
        margin: 0 0 15px 0;
        font-family: $fontRoundedBook;
        text-align: center;
      }

      md-input-container.name,
      md-input-container.email,
      md-input-container.organization-name {
        padding-left: 0;
        padding-right: 0;

        .label {
          text-transform: capitalize;
        }
      }

      md-checkbox {
        margin: 20px 0 0 0;

        .md-label {
          margin-top: 0;

          &:first-letter {
            text-transform: capitalize;
          }
        }
      }

      md-autocomplete {
        margin-top: 12px;

        md-input-container {
          .label {
            text-transform: capitalize;
          }
        }
      }

      .error-messages {
        div {
          font-size: 0.8rem;
        }
      }

      .md-button.ck-primary {
        margin: 20px 0 0 0;
      }
    }
  }

  .identify-purchase-period {
    .ck-dialog-header {
      height: $teacherHeaderHeight;

      .ck-close,
      .ck-back {
        color: $ckGreen;
      }
    }

    .ck-dialog-body {
      max-width: 500px;
      min-width: 500px;
      margin: 0 60px 60px 60px;

      .ck-dialog-title {
        @extend .classkick-header;
        text-align: center;
        margin-bottom: 40px;
        text-transform: capitalize;
      }

      .body-content {
        .quantity {
          margin-bottom: 32px;

          .label {
            margin-bottom: 8px;
          }

          md-input-container {
            padding: 0;
            margin-top: 0;
            width: 75px;
          }

          .stepper {
            position: relative;
            top: -2px;
            margin-left: 6px;
            margin-right: 8px;

            .increment,
            .decrement {
              height: 12px;
              cursor: pointer;
              outline: none;

              i {
                color: $ckHeaderGrey;
              }
            }
          }
        }

        .purchase-period {
          .label {
            margin: 0 0 12px 0;
          }
        }
      }

      .md-button.ck-primary {
        margin: 20px 0 0 0;
      }

      .error-message {
        color: $ckWarn;
        text-align: center;
        height: 20px;
        margin: 8px;
        font-family: $fontRoundedBook;
      }
    }
  }

  .loading {
    width: 100px;
    height: 100px;
  }
}

.pro-trial-overlay-extension {
  box-sizing: border-box;
  color: $ckAltGrey;
  max-width: 60rem;
  width: 60rem;
  box-shadow:
    0px 12px 16px rgba(0, 0, 0, 0.12),
    0px 16px 24px rgba(0, 0, 0, 0.08),
    0px 24px 32px rgba(0, 0, 0, 0.08),
    0px 32px 40px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  padding: 32px;

  .dialog-header {
    font-family: $fontRoundedBold;
    font-size: 3.4rem;
    line-height: 3.2rem;
    text-align: center;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0.8rem 0 4.4rem 0;

    .header-title {
      max-width: 33.4rem;
    }

    .close {
      margin-right: 13px;
      cursor: pointer;
      &:hover {
        i {
          color: $ckGreenProgress;
        }
      }

      .ck-close {
        font-size: 1.4rem;
        color: $ckAltGrey;
      }
    }
  }

  .body {
    font-family: $fontRoundedBook;
    font-size: 1.4rem;
    box-sizing: border-box;
    padding: 4px 12px 0px 12px;
    border-radius: 8px;
    margin-bottom: 36px;
  }

  .sub-title {
    font-size: 2.4rem;
    color: $ckGreenProgress;
    text-align: center;
    font-family: $fontRoundedBold;
    margin-bottom: 28px;
    line-height: 3.2rem;

    span {
      text-transform: capitalize;
    }
  }

  .summary {
    color: $slate;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    font-family: $fontRoundedBold;
  }
}

.import-image-dialog {
  max-height: 95%;
  text-align: center;

  .close-dialog {
    @extend .md-button.square;
    margin: 0;
    min-width: 60px;
    height: unset;
  }

  md-dialog-content {
    overflow: hidden;
  }

  .dialog-header {
    min-height: 60px;
  }

  .content {
    min-width: 40vw;
    min-height: 10vh;
  }

  .crop {
    .content {
      .directions {
        margin-bottom: 16px;

        a {
          margin-left: 4px;
          text-transform: capitalize;
        }
      }

      .image-container {
        background-color: $backgroundGrey;
        border-top: 1px solid $dividerGrey;
        border-bottom: 1px solid $dividerGrey;
        overflow: hidden;

        height: calc(90vh - 14em);
        width: 60vw;

        .ck-loading {
          color: white;
        }

        .cropper {
          height: 100%;
          width: 100%;
        }
      }
    }

    .actions {
      padding: 10px 24px 24px 24px;

      .md-button {
        margin-bottom: 0;
      }

      .md-button:last-child {
        margin-right: 0;
      }
    }
  }

  .choose-destinations {
    width: 550px;

    .content {
      max-height: calc(95vh - 10em);
      min-width: 25em;
      overflow: auto;

      .title {
        @extend .classkick-header;
        margin-bottom: 10px;
      }

      .scroll-container {
        height: auto;
      }

      .cropped-preview {
        margin: 8px;
        border: 1px solid $borderGrey;
        max-width: 70vw;
        max-height: 30vh;
      }

      .directions {
        margin: 40px 0 10px 0;
      }

      .dest-select {
        padding: 0 8px 2em 8px;

        md-input-container {
          min-width: 20em;
          max-width: 65vw;
          text-align: left;

          .label {
            text-transform: none;
          }

          // TODO HACK: Check if this is still borked after upgrading off of Angular Material RC5
          // This prevents
          .md-select-value div {
            display: inline;
          }
        }
      }
    }

    .actions {
      padding: 10px 24px 24px 24px;

      .md-button {
        margin-bottom: 0;
      }

      .md-button:last-child {
        margin-right: 0;
      }
    }
  }

  .review {
    .content {
      .title {
        @extend .classkick-header;
        margin-bottom: 10px;
      }

      md-list {
        width: 60vw;
        min-width: 40em;
        height: 40vh;

        overflow: auto;

        background: white;
        border-top: 1px solid $dividerGrey;
        border-bottom: 1px solid $dividerGrey;

        md-list-item {
          border-bottom: 1px solid $dividerGrey;

          &._md-button-wrap > div.md-button:first-child {
            padding: 0 2.4rem;
          }

          .selection {
            width: 100%;
            font-family: $fontRoundedBook;
            color: $ckHeaderGrey;

            .img-container {
              margin: 0.2em 1em 0.2em 0.2em;
              width: 100px;
              height: 100px;
              padding: 1px;
              background: $backgroundGrey;

              img {
                max-width: 100%;
                max-height: 100%;
              }
            }

            .selection-list {
              line-height: 1.3em;
            }
          }
        }
      }
    }

    .actions {
      padding: 30px 24px 24px 24px;

      .md-button {
        margin-bottom: 0;
      }

      .md-button:last-child {
        margin-right: 0;
      }
    }
  }

  .load-pdf {
    .content {
      margin: 2em;
    }
  }

  .cancel-check {
    .content {
      .primary-message {
        @extend .classkick-header;
        padding: 0 60px;
        margin-bottom: 24px;
      }

      .secondary-message {
        @extend .classkick-header;
        padding: 0 60px;
        margin-bottom: 24px;
        font-family: $fontRoundedBook;
      }
    }

    .actions {
      padding: 30px 11px 14px 24px;

      .md-button {
        margin-bottom: 0;
      }

      .md-button:last-child {
        margin-right: 0;
      }
    }
  }

  .crop-page-btn {
    min-width: 40px;
    min-height: 40px;
    color: $ckHeaderGrey;

    &.md-button[disabled] {
      color: rgba(0, 0, 0, 0.38);
    }
  }
}

.dest-select-menu {
  @extend .ck-select-menu;

  md-option {
    .md-icon {
      transform: scale(0.7);
    }

    .md-text {
      height: 1.6rem;
      margin-top: 0.2rem;
    }
  }
}
